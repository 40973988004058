<template>
  <div class="diagrambox">
    <div class="diagrambox-center">
      <div>
        <!-- <el-select v-model="environmentid" placeholder="请选择" @change="selectbtn()">
          <el-option v-for="item in architecturelist" :key="item.id" :label="item.menuName"
            :value="item.id" :popper-append-to-body="false">
          </el-option>
        </el-select> -->
      </div>
      <div class="navigationtree" v-if="environmentid === 28">
        <el-tree
          ref="leftTree"
          node-key="id"
          default-expand-all
          :data="diagramlist"
          :props="defaultProps"
          show-checkbox
          check-strictly
          @check-change="handleNodeClick"
        >
        </el-tree>
      </div>
      <div class="navigationtree" v-if="environmentid === 29">
        <TitleBox :titlename="'样地样方'" />
        <div class="patrolbox">
          <ul>
            <li>
              <p>时间</p>
              <p>名称</p>
              <p>类型</p>
              <p>采集员</p>
            </li>
            <li
              v-for="(item, index) in Thirdlist"
              :key="index"
              @click="clickpatrollist(item, index)"
            >
              <p>{{ item.endTime }}</p>
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.taskName"
                placement="top"
              >
                <p>{{ item.taskName }}</p>
              </el-tooltip>

              <p>{{ item.observationTaskType_dictText }}</p>
              <p>{{ item.taskUserName }}</p>
            </li>
          </ul>
        </div>
        <div class="paging">
          <el-pagination
            small
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageobj.pageNo"
            :page-sizes="[15]"
            :page-size="pageobj.pageSize"
            layout="total, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="navigationtree" v-if="environmentid === 120">
        <TitleBox :titlename="'观测路线'" />
        <div class="patrolbox">
          <ul>
            <li>
              <p>时间</p>
              <p>名称</p>
              <p>类型</p>
              <p>采集员</p>
            </li>
            <li v-for="(item, index) in listThirdlist" :key="index" @click="clickpalistThirdlist(item, index)">
              <p>{{ item.endTime }}</p>
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.taskName"
                placement="top"
              >
                <p>{{ item.taskName }}</p>
              </el-tooltip>
              <p>{{ item.observationTaskType_dictText }}</p>
              <p>{{ item.taskUserName }}</p>
            </li>
          </ul>
        </div>
        <div class="paging">
          <el-pagination
            small
            background
            @size-change="listThirdSizeChange"
            @current-change="listThirdCurrentChange"
            :current-page="listThirdobj.pageNo"
            :page-sizes="[15]"
            :page-size="listThirdobj.pageSize"
            layout="total, prev, pager, next"
            :total="listThirdtotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import api from '../visualization/api'
import TitleBox from '../visualization/components/title/index.vue'
export default {
  props: ['environmentid', 'op'],
  components: {
    TitleBox
  },
  data () {
    return {
      isAction: -1,
      treeshow: false,
      defaultProps: {
        children: 'children',
        label: 'categoryName'
      },
      diagramlist: [],
      pageobj: {
        pageNo: 1,
        pageSize: 15
      },
      total: null,
      Thirdlist: [],
      listThirdobj: {
        pageNo: 1,
        pageSize: 15
      },
      listThirdtotal: null,
      listThirdlist: []
    }
  },
  computed: {},
  watch: {
    op: {
      // immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.op = newvalue
          // console.log(this.env, 'zizujianop')
        })
      }
    },
    environmentid: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          if (newvalue === 28) {
            this.treeshow = true
            this.pagediversitytree()
          } else if (newvalue === 29) {
            this.treeshow = false
            this.pagelistThird()
          } else if (newvalue === 120) {
            this.treeshow = false
            this.pagetasklistThird()
          }
        })
      }
    }
  },
  methods: {
    handleNodeClick (data, checked) {
      // console.log(data, checked, 'data')
      if (checked) {
        this.$refs.leftTree.setCheckedNodes([data])
        this.op.GetEnvHelper().BiodiversityLoad(data.id, (res) => {
          console.log(res)
        })
      } else {
        this.op.GetEnvHelper().Dispose()
      }
    },
    clickpatrollist (item, index) {
      this.op.GetEnvHelper().Dispose()
      this.op.GetEnvHelper().SampleLoad(item.id, (res) => {
        console.log(res)
      })
    },
    clickpalistThirdlist (item, index) {
      this.op.GetEnvHelper().Dispose()
      this.op.GetEnvHelper().ObservationalRoute(item.id, (res) => {
        console.log(res)
      })
    },
    pagediversitytree () {
      api.pagediversity().then((res) => {
        this.diagramlist = res.data
      })
    },
    pagelistThird () {
      api.pagelistThird(this.pageobj).then((res) => {
        this.Thirdlist = res.data.records
        this.total = res.data.total
      })
    },
    // 巡护分页
    handleSizeChange (val) {
      this.pageobj.pageSize = val
      this.pagelistThird()
    },
    // 巡护切换分页
    handleCurrentChange (val) {
      this.pageobj.pageNo = val
      this.pagelistThird()
    },
    pagetasklistThird () {
      api.pagetasklistThird(this.listThirdobj).then((res) => {
        this.listThirdlist = res.data.records
        this.listThirdtotal = res.data.total
      })
    },
    // 巡护分页
    listThirdSizeChange (val) {
      this.listThirdobj.pageSize = val
      this.pagetasklistThird()
    },
    // 巡护切换分页
    listThirdCurrentChange (val) {
      this.listThirdobj.pageNo = val
      this.pagetasklistThird()
    }
  },
  created () {
    // this.pagediversitytree()
  }
}
</script>
<style lang="less" scoped>
.diagrambox {
  width: 100%;
  height: 100%;
  background: transparent;
  /deep/.el-input__inner {
    background-color: rgba(230, 252, 255, 0.3);
    border: none;
    color: #bfdafd;
    font-size: 12px;
    border-radius: 0px;
  }
  &-center {
    width: 100%;
    height: 100%;
    // background: #83b4ef;
    margin: 20px 0 1% 3%;
    // overflow: hidden;
    font-size: 20px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #e6fcff;
    .navigation {
      width: 100%;
      margin-top: 5%;
    }
    .navigationtree {
      width: 100%;
      height: 100%;
      // background: red;
      margin-top: 5%;
      overflow-y: auto;
      .patrolbox {
        width: 100%;
        height: 100%;
        // background: #0090ff;
        margin-top: 15px;
        ul {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          li {
            cursor: pointer;
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            p {
              width: 25%;
              text-align: center;
              line-height: 30px;
              color: #fff;
              margin: 0;
              font-size: 14px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          li:hover {
            background: rgba(79, 119, 163, 0.4);
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
      }
      .paging {
        position: absolute;
        bottom: 2%;
        left: 0;
        width: 96%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        /deep/.el-input__inner {
          background: transparent;
          border: 1px solid #abdaff;
          color: #fff;
        } //input背景颜色
        /deep/.btn-prev {
          background: transparent;
          color: #fff;
        } //左箭头按钮背景
        /deep/.btn-next {
          background: transparent;
          color: #fff;
        } //右箭头按钮背景
        /deep/.number {
          background: transparent;
          color: #fff;
        } //未被选中的页码背景
        /deep/.active {
          background: #0090ff !important;
          color: #fff;
        } //被选中的页码背景
        /deep/.el-pagination.is-background .el-pager li {
          background: transparent;
          color: #fff;
        } //被折叠的页码背景
        /deep/.el-pagination__total {
          color: #fff;
        } //总条数字体颜色
        /deep/.el-pagination__jump {
          color: #fff;
        } //前往第几页数字体颜色
      }

      /deep/.el-tree-node {
        .is-leaf + .el-checkbox .el-checkbox__inner {
          display: inline-block;
        }
        .el-checkbox .el-checkbox__inner {
          display: none;
        }
      }
      //树形结构
      /deep/.el-tree {
        background-color: transparent;
        color: #fff;
      } //背景颜色
      /deep/.el-tree-node__content {
        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      } //鼠标划入颜色
      /deep/.el-tree-node.is-current > .el-tree-node__content {
        background-color: transparent !important;
      } //点击背景颜色
      /deep/.el-tree-node:focus > .el-tree-node__content {
        background-color: transparent !important;
      } //点击父级背景颜色
      /deep/.el-checkbox__input.is-disabled .el-checkbox__inner {
        background-color: #878987;
        border-color: #878987;
      }
    }
  }
  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: RGBA(56, 74, 97, 0.2);
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: RGBA(56, 74, 97, 0.6);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }
}
</style>
